import { Button } from "./button";
import { ContentViewProps } from "../model";
import { splitProps } from "solid-js";

export function Content(props: ContentViewProps) {
  const [local, others] = splitProps(props, [
    "title",
    "desription",
    "image",
    "btnTitle",
    "hasTopping",
    "onClick",
    "onclick",
  ]);
  const hasTopping = local.hasTopping ?? true;
  return (
    <div class="flex flex-col justify-center items-center w-full" {...others}>
      {hasTopping && <hr class="w-full h-2px bg-[#BDD2EA]" />}

      <div id="main-rs-crd" class="flex mx-2.5 my-2.5 w-full">
        {!!local.image?.src ? (
          <img
            class="img-placeholder"
            src={local.image.src}
            alt={local.image.alt}
            width={local.image.width}
            height={local.image.height}
          />
        ) : (
          <div id="img-placeholder" class="img-placeholder" />
        )}
        <div id="content-container" class="flex flex-col items-start mx-5 gap-y-10px w-full text-left">
          <p id="rsc-title" class="text-20px font-bold">
            {local.title}
          </p>
          <p id="rsc-desc" class="text-16px w-80%">
            {local.desription}
          </p>
          <Button
            title={local.btnTitle}
            onClick={() => (local.onClick as any)?.()}
            onclick={() => (local.onclick as any)?.()}
          />
        </div>
      </div>
    </div>
  );
}
